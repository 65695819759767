import { Navigate, Route, Routes } from "react-router-dom";
// import { Amplify, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import AWS from "aws-sdk";
// import Login from "../pages/Auth/Login";
// import Register from "../pages/Auth/Register";
// import Verify from "../pages/Auth/Verfiy";
// import ForgotPassword from "../pages/Auth/ForgotPassword";
import Analysis from "../pages/Main/Analysis";
import AnalysisDetail from "../pages/Main/Analysis/details";
// import LandingPage from "../pages/Main/LandingPage";
// import ProtectedRoute from "./ProtectedRoute";

// common routes
import TermsOfService from "../pages/Common/TermOfService";
import PrivacyPolicy from "../pages/Common/PrivacyPolicy";
import CookiePolicy from "../pages/Common/CookiePolicy";
import Disclaimer from "../pages/Common/Disclaimer";
import { loginUserData } from "../store/action";

// Initialize AWS SDK with your Identity Pool ID
const identityPoolId = "us-east-2:a1d4b7c0-0436-48ef-80a5-e1f3463953a6";
const RoleArn =
  "arn:aws:iam::826198776617:role/Cognito_rpatidentitypoolUnauth_Role";

// Set up AWS SDK with the Identity Pool ID
// const client = new AWS.IAM({
//   region: "us-east-2",
//   credentials: fromCognitoIdentityPool({
//     // Required. The unique identifier for the identity pool from which an identity should be
//     // retrieved or generated.
//     identityPoolId: "us-east-1:1699ebc0-7900-4099-b910-2df94f52a030",
//     // Optional. The ARN of the role to be assumed when multiple roles were received in the token
//     // from the identity provider.
//     customRoleArn: "arn:aws:iam::1234567890:role/MYAPP-CognitoIdentity",
//     // Optional. Custom client config if you need overwrite default Cognito Identity client
//     // configuration.
//     clientConfig: { region: "us-east-2" }
//   })
// });
// console.log({ client });
// Optionally, you can set the credentials to the AWS SDK client

// const awsExports = {
//   aws_cognito_identity_pool_id:
//     "us-east-2:a1d4b7c0-0436-48ef-80a5-e1f3463953a6",
//   aws_cognito_region: "us-east-2",
//   aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
//   aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
// };

// Amplify.configure(awsExports);
AWS.config.update({ region: "us-east-2" });

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: identityPoolId
});

const generateUniqueUsername = () => {
  const timestamp = Date.now(); // Current timestamp
  const randomString = Math.random().toString(36).substring(2, 8); // Random alphanumeric string
  return `Guest_${randomString}_${timestamp}`;
};

// function stsGetCallerIdentity(creds) {
//   var stsParams = { credentials: creds };
//   // Create STS service object
//   var sts = new AWS.STS(stsParams);

//   sts.getCallerIdentity({}, function (err, data) {
//     if (err) {
//       console.log(err, err.stack);
//     } else {
//       console.log(data.Arn);
//     }
//   });
// }

function Router() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [documentVisible, setDocumentVisibility] = useState(false);

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      setDocumentVisibility(true);
    } else {
      setDocumentVisibility(false);
    }
  });

  useEffect(() => {
    const checkAuthSession = async () => {
      try {
        // const anonymousUser = await Auth.currentCredentials();
        // console.log({ anonymousUser });

        // AWS.config.update({
        //   region: "YOUR_AWS_REGION",
        //   credentials: {
        //     accessKeyId: anonymousUser.accessKeyId, // The temporary AWS Access Key ID
        //     secretAccessKey: anonymousUser.secretAccessKey, // The temporary AWS Secret Access Key
        //     sessionToken: anonymousUser.sessionToken, // The temporary AWS Session Token
        //     expiration: anonymousUser.expiration // The timestamp when the credentials will expire
        //   }
        // });

        // var getIdParams = {
        //   IdentityPoolId: identityPoolId,
        //   AccountId: "826198776617"
        // };

        // var cognitoIdentity = new AWS.CognitoIdentity({
        //   apiVersion: "2014-06-30"
        // });

        // cognitoIdentity.getId(getIdParams, function (err, data) {
        // if (err) {
        //   console.log("Error" + err);
        // } else {
        // console.log("Cognito Identity ID is " + data.IdentityId);
        // var params = {
        //   IdentityId: data.IdentityId /* required */
        // };
        // cognitoIdentity.getCredentialsForIdentity(
        // params,
        // function (err, data) {
        //   if (err) console.log(err, err.stack); // an error occurred
        //   else {
        //     console.log({ data, AWS: AWS.config.credentials });
        //     var params = {
        //       IdentityPoolId: identityPoolId /* required */
        //     };
        // AWS.config.credentials = data;
        // cognitoIdentity.getIdentityPoolRoles(
        //   params,
        //   function (err, IdentityPoolRoles) {
        //     if (err) console.log(err, err.stack); // an error occurred
        //     else console.log({ IdentityPoolRoles }); // successful response
        //   }
        // );

        // const apiGateway = new AWS.APIGateway();
        // apiGateway
        //   .getRestApis()
        //   .promise()
        //   .then((response) => {
        //     console.log({ response });
        //   })
        //   .then((response) => {
        //     console.log("API Gateway REST API:", response);
        //   })
        //   .catch((error) => {
        //     console.log("Error getting API Gateway REST API:", {
        //       error
        //     });
        //   });
        //   } // successful response
        // }
        // );
        // const roleToAssume = {
        //   RoleArn:
        //     "arn:aws:iam::826198776617:role/Cognito_rpatidentitypoolUnauth_Role",
        //   RoleSessionName: "CognitoIdentityCredentials",
        //   DurationSeconds: 1000
        // };

        // let roleCreds;

        // const sts = new AWS.STS({ apiVersion: "2011-06-15" });

        // sts.assumeRole(roleToAssume, function (assumeRoleError, data) {
        //   if (assumeRoleError) console.log({ assumeRoleError });
        //   else {
        //     roleCreds = {
        //       accessKeyId: data.Credentials.AccessKeyId,
        //       secretAccessKey: data.Credentials.SecretAccessKey,
        //       sessionToken: data.Credentials.SessionToken
        //     };
        //     stsGetCallerIdentity(roleCreds);
        //   }
        // });
        // const apiGateway = new AWS.APIGateway();
        // apiGateway
        //   .getRestApis()
        //   .promise()
        //   .then((response) => {
        //     console.log({ response });
        //   })
        //   .then((response) => {
        //     console.log("API Gateway REST API:", response);
        //   })
        //   .catch((error) => {
        //     console.log("Error getting API Gateway REST API:", { error });
        //   });
        // }
        // });

        dispatch(
          loginUserData({
            token: "anonymousUser.sessionToken",
            refreshToken: "anonymousUser.secretAccessKey",
            username: generateUniqueUsername(),
            email: "anonymousUser.accessKeyId"
          })
        );
      } catch (err) {
        console.log({ err });
      }
    };
    checkAuthSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Analysis />} />
      <Route path="/details" element={<AnalysisDetail />} />
      <Route path="/terms-of-services" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default Router;
