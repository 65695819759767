import { Link } from "react-router-dom";
import "./style.css";

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  return (
    <footer className="py-3 my-4">
      <ul className="nav justify-content-center border-bottom pb-3 mb-3">
        <li className="nav-item">
          <Link className="nav-link px-2 text-muted" to="/terms-of-services">
            Terms of Service
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link px-2 text-muted" to="/privacy-policy">
            Privacy Policy
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link px-2 text-muted" to="/cookie-policy">
            Cookie Policy
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link px-2 text-muted" to="/disclaimer">
            Disclaimer
          </Link>
        </li>
      </ul>
      <p className="text-center text-muted">© {currentYear} Analyze Properties LLC</p>
    </footer>
  );
};

export default Footer;
