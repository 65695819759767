import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

import Button from "../../../components/Button";
import Header from "../../../components/Header";
import Loader from "../../../components/Loader";

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import "./style.css";
import Footer from "../../../components/Footer";

const AnalysisDetail = () => {
  //console.log("AnalysisDetails component loaded");

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.gray,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.gray,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      width: "45%",
    },
  }));

  const navigate = useNavigate();
  const address = useSelector((state) => state?.search);
  const analysisOutput = useSelector((state) => state?.analysisOutput);
  const { username, token } = useSelector((state) => state.userData);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [apiInputsData, setApiInputsData] = useState();
  const [calculatedInputsData, setCalculatedInputsData] = useState();
  const [mortagePaymentPercent, setMortagePaymentPercent] = useState("");
  const [mortageInterest, setMortageInterest] = useState("");

  useEffect(() => {
    setInitialData(analysisOutput);
  }, [analysisOutput]);

  const setInitialData = (response) => {
    setData(response);
    setApiInputsData(response?.apiInputs);
    setCalculatedInputsData(response?.calculatedInputs);
    setMortagePaymentPercent(
      response?.calculatedInputs?.downPaymentPercentage.toString()
    );
    setMortageInterest(response?.apiInputs?.mortgageInterestRate.toString());
  };

  const onDataChange = (type, key, value) => {
    if (type === "api") {
      setApiInputsData({
        ...apiInputsData,
        [key]: +value
      });
    } else if (type === "calculated") {
      setCalculatedInputsData({
        ...calculatedInputsData,
        [key]: +value
      });
    }
  };

  const onReRun = () => {
    delete calculatedInputsData?.mortgagePayment;
    setLoading(true);
    axios
      .post(
        "https://w4k3ya81hi.execute-api.us-east-2.amazonaws.com/prod/analysis",
        {
          userId: username,
          address: address,
          apiInputs: apiInputsData,
          calculatedInputs: calculatedInputsData
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
            "X-Api-Key": "CHCZCNwnZ96mxnF4khlvH92c7Sdn3SobzgUuVGb1"
          }
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data?.Message === "Success") {
          setData(response.data?.AnalysisOutput);
          setCalculatedInputsData({
            ...calculatedInputsData,
            mortgagePayment:
              response.data?.AnalysisOutput?.calculatedInputs?.mortgagePayment
          });
        } else {
          alert(
            "Sorry! Something went wrong when trying to process that property, please refresh the page and try again."
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        if (err.response?.data?.message) {
          alert(err.response.data?.message);
        } else if (err.code === "ERR_NETWORK") {
          alert(
            "Your auth token has expired, please refresh the page and try again."
          );
        } else {
          alert(
            "Sorry! Something went wrong when trying to process that property, please refresh the page and try again."
          );
        }
      });
  };

  const numberRenderer = (value) => {
    return !isNaN(value) ? parseFloat(value.toFixed(2)) : "";
  };

  //Tooltip blurbs:
  const mcfTitle="This is the expected cash flow that this property will generate each month. This metric does not include expenses that may vary each month, such as maintenance costs, vacancy, capital expenditures."

  const mcfnTitle="This is the expected cash flow that this property will generate each month, when setting aside capital for variable expenses, such as maintenance costs, vacancy, capital expenditures. This metric includes all monthly income and both fixed and variable expenses."

  const mIncomeTitle="The sum of all monthly income streams."

  const mExpensesTitle="The sum of all monthly expense streams (both fixed and variable)."

  const cocTitle="Cash on Cash Return on Investment - estimated annual return of the initial cash investment needed to purchase the property."

  const noiTitle="Net Operating Income - annual profit expected from the property, when it is an all-cash purchase (no mortgage)."

  const capRateTitle="Capitalization Rate - an estimation of a property's potential rate of return, which compares the property's NOI to its market value. The CAP rate calculation assumes an all-cash purchase."

  const fiftyRuleTitle="A key back-of-the-napkin calculation for rental properties - it measures 50% of the expected rental income against the fixed expenses of the property." 

//Input blurbs:
  const inputTitle="The tool has found as much data as it can about this property, and has presented an initial analysis for you here - but this is not the end! Now you should refine each input based on your expertise, market knowledge, and investment approach, and then re-run the analysis to finalize all profitability metrics!"

  const purchasePriceTitle="This is the price at which you expect to purchase the property."

  const closingCostsTitle="The generated closing cost numbers are a rough estimate based on state averages - as state laws can have the largest impact on a buyer’s closing costs. Even though this value doesn’t have a significant impact on property cash flow metrics, it is recommended that you adjust this value accordingly."

  const insuranceTitle="This annual value is an estimate based on state averages, it is recommended that you adjust it according to your best estimate, as it will impact your fixed monthly expenses."

  const taxesTitle="This annual value is derived from either the latest estimated property tax record for this property or by multiplying the county tax rate against the estimated purchase price."

  const mdPaymentTitle="The expected percentage of cash down payment on the mortgage. The default value is 25%. For a cash-only purchase, set this to 100%."

  const mirTitle="The estimated interest rate on the mortgage, assuming a 30-year fixed-rate loan. This rate will vary daily, and for each lender, so adjust it accordingly."

  const mortagePaymentTitle="The resulting monthly estimated mortgage payment, based on the mortgage down payment and interest rate. This mortgage calculator assumes a 30-year fixed-rate loan."

  const estRentTitle="This value is an estimate of the rent that the property may be able to generate, based on its location, size, and comparable rent values. This is a critical value in the cash flow calculations, so it is essential that you update it accordingly based on the rent you believe the property can generate."

  const hoaTitle="The expected Homeowner Association fees for this property. Please note that this data is not always available, and it may reflect as $0, when there actually should be a HOA fee. Please review and update this value accordingly!"

  const maintenanceTitle="This is a variable cost based on an average expected monthly maintenance cost for the property. The default value here is an expense allocation of 5% of the expected rent revenue."

  const vacancyTitle="This is a planned monthly reservation of capital that will be used to cover the cost of a vacancy (when the property is not rented). The default value here is an expense allocation of 5% of the expected rent revenue."

  const capExpTitle="This is a planned monthly reservation of capital that will be used to cover costs for major repairs or investments, such as a new roof or bathroom rehab. The default value here is an expense allocation of 5% of the expected rent revenue."

  const propManagmentTitle="If you plan to do your own property management, this value will be $0 (the default). However, if you plan to use a property manager or management company, then you should add your expected monthly cost here. These costs vary, but can typically be about 10% of your monthly rent revenue."

  return (
    <div className="relative">
      <Header />
      {loading && <Loader />}
      <div className="detail-wrapper container-main my-4">
        <div className="d-flex justify-content-between align-items-center">
          <Button
            title="Analyze New Property"
            className="btn-analysis"
            onClick={() => navigate("/analysis")}
            color="#279ac8"
          />
          <Button
            title="Provide Feedback"
            className="btn-analysis"
            onClick={() => window.open("https://forms.gle/4GZTw8M24Fm2dgxB6")}
            color="#279ac8"
          />
        </div>
        <h2 className="text-main mt-5">Rental Property Analysis Tool</h2>
        <p className="sub-text">
          <span className="fw-bold">Property Address : </span> {address}
        </p>
        <div className="row mb-2">
          <div className="col-lg-1"></div>
          <div className="col-lg-6">
            <div>
              <h5 className="fw-bold text-black">Analysis:</h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-5 col-md-6 lh-5 mb-2">
            <div className="card card-detail">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <p>Monthly Cash Flow :&nbsp;
                    <BootstrapTooltip 
                      title={mcfTitle} 
                      placement="right-start"
                      enterTouchDelay={0}
                      leaveTouchDelay={10000}
                      arrow
                    >
                      <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                    </BootstrapTooltip>
                  </p>
                  <p>
                    {
                      parseFloat(data?.analysisOutputs?.monthlyCashFlowNoVarExp)
                      .toLocaleString('en-US', {style:'currency', currency:'USD'})
                    }
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Monthly Cash Flow (w/ variable exp) :&nbsp;
                    <BootstrapTooltip 
                      title={mcfnTitle} 
                      placement="right-start" 
                      enterTouchDelay={0} 
                      leaveTouchDelay={10000} 
                      arrow
                    >
                      <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                    </BootstrapTooltip>
                  </p>
                  <p>
                    {
                      parseFloat(data?.analysisOutputs?.monthlyCashFlow)
                      .toLocaleString('en-US', {style:'currency', currency:'USD'})
                    }
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p> Monthly Income :&nbsp;
                    <BootstrapTooltip 
                      title={mIncomeTitle} 
                      placement="right-start" 
                      enterTouchDelay={0} 
                      leaveTouchDelay={10000} 
                      arrow
                    >
                      <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                    </BootstrapTooltip>
                  </p>
                  <p>
                    {
                      parseFloat(data?.analysisOutputs?.monthlyIncome)
                      .toLocaleString('en-US', {style:'currency', currency:'USD'})
                    }
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Monthly Expenses :&nbsp;
                    <BootstrapTooltip 
                      title={mExpensesTitle} 
                      placement="right-start" 
                      enterTouchDelay={0} 
                      leaveTouchDelay={10000} 
                      arrow
                    >
                      <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                    </BootstrapTooltip>
                  </p>
                  <p>
                    {" "}
                    {
                      parseFloat(data?.analysisOutputs?.monthlyExpenses)
                      .toLocaleString('en-US', {style:'currency', currency:'USD'})
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            <div className="card card-detail">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <p>CoC ROI :&nbsp;
                    <BootstrapTooltip 
                      title={cocTitle} 
                      placement="right-start" 
                      enterTouchDelay={0} 
                      leaveTouchDelay={10000} 
                      arrow
                    >
                      <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                    </BootstrapTooltip>
                  </p>
                  <p>
                    {
                      parseFloat(data?.analysisOutputs?.cocRoi)
                      .toLocaleString(undefined, {maximumFractionDigits: 2 })
                    }%
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>NOI :&nbsp;
                    <BootstrapTooltip 
                      title={noiTitle} 
                      placement="right-start" 
                      enterTouchDelay={0} 
                      leaveTouchDelay={10000} 
                      arrow
                    >
                      <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                    </BootstrapTooltip>
                  </p>
                  <p>
                    {
                      parseFloat(data?.analysisOutputs?.noi)
                      .toLocaleString('en-US', {style:'currency', currency:'USD'})
                    }
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>CAP Rate :&nbsp;
                    <BootstrapTooltip 
                      title={capRateTitle} 
                      placement="right-start" 
                      enterTouchDelay={0} 
                      leaveTouchDelay={10000} 
                      arrow
                    >
                      <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                    </BootstrapTooltip>
                  </p>
                  <p>
                    {
                      parseFloat(data?.analysisOutputs?.capRate)
                      .toLocaleString(undefined, {maximumFractionDigits: 2 })
                    }%
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>50% Rule :&nbsp;
                    <BootstrapTooltip 
                      title={fiftyRuleTitle} 
                      placement="right-start" 
                      enterTouchDelay={0} 
                      leaveTouchDelay={10000} 
                      arrow
                    >
                      <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                    </BootstrapTooltip>
                  </p>
                  <p>
                    {
                      parseFloat(data?.analysisOutputs?.fiftyPercentRule)
                      .toLocaleString('en-US', {style:'currency', currency:'USD'})
                    }
                  </p>
                </div>
                <div> </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>

        <div className="row mb-2 mt-8">
          <div className="col-lg-2"></div>
          <div className="col-lg-6">
            <div>
              <h5 className="fw-bold text-black">Inputs:&nbsp;
                <BootstrapTooltip 
                  title={inputTitle} 
                  placement="right-end" 
                  enterTouchDelay={0} 
                  leaveTouchDelay={10000} 
                  arrow
                >
                  <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                </BootstrapTooltip>
              </h5>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 col-md-12">
            <div className="row g-3">
              <div className="col-md-6 relative">
                <label className="form-label">Purchase Price:&nbsp;
                  <BootstrapTooltip 
                    title={purchasePriceTitle} 
                    placement="right-end" 
                    enterTouchDelay={0} 
                    leaveTouchDelay={10000} 
                    arrow
                  >
                    <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                  </BootstrapTooltip>
                </label>
                <span className="price">$</span>
                <input
                  className="form-input"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  value={numberRenderer(apiInputsData?.purchasePrice)}
                  onChange={(e) =>
                    onDataChange("api", "purchasePrice", e.target.value)
                  }
                />
              </div>
              <div className="col-md-6 relative">
                <label className="form-label">Estimated Rent:&nbsp;
                  <BootstrapTooltip 
                    title={estRentTitle} 
                    placement="right-end" 
                    enterTouchDelay={0} 
                    leaveTouchDelay={10000} 
                    arrow
                  >
                    <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                  </BootstrapTooltip>
                </label>
                <span className="price">$</span>
                <input
                  type="number"
                  className="form-input"
                  onWheel={(e) => e.target.blur()}
                  value={numberRenderer(apiInputsData?.estimatedRent)}
                  onChange={(e) =>
                    onDataChange("api", "estimatedRent", e.target.value)
                  }
                />
              </div>
              <div className="col-md-6 relative">
                <label className="form-label">Closing Costs:&nbsp;
                  <BootstrapTooltip 
                    title={closingCostsTitle} 
                    placement="right-end" 
                    enterTouchDelay={0} 
                    leaveTouchDelay={10000} 
                    arrow
                  >
                    <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                  </BootstrapTooltip>
                </label>
                <span className="price">$</span>
                <input
                  type="number"
                  className="form-input"
                  onWheel={(e) => e.target.blur()}
                  value={numberRenderer(calculatedInputsData?.closingCosts)}
                  onChange={(e) =>
                    onDataChange("calculated", "closingCosts", e.target.value)
                  }
                />
              </div>
              <div className="col-md-6 relative">
                <label className="form-label">HOA Fee:&nbsp;
                  <BootstrapTooltip 
                    title={hoaTitle} 
                    placement="right-end" 
                    enterTouchDelay={0} 
                    leaveTouchDelay={10000} 
                    arrow
                  >
                    <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                  </BootstrapTooltip>
                </label>
                <span className="price">$</span>
                <input
                  type="number"
                  className="form-input"
                  onWheel={(e) => e.target.blur()}
                  value={numberRenderer(apiInputsData?.hoa)}
                  onChange={(e) => onDataChange("api", "hoa", e.target.value)}
                />
              </div>
              <div className="col-md-6 relative">
                <label className="form-label">Insurance:&nbsp;
                  <BootstrapTooltip 
                    title={insuranceTitle} 
                    placement="right-end" 
                    enterTouchDelay={0} 
                    leaveTouchDelay={10000} 
                    arrow
                  >
                    <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                  </BootstrapTooltip>
                </label>
                <span className="price">$</span>
                <input
                  type="number"
                  className="form-input"
                  onWheel={(e) => e.target.blur()}
                  value={numberRenderer(calculatedInputsData?.insurance)}
                  onChange={(e) =>
                    onDataChange("calculated", "insurance", e.target.value)
                  }
                />
              </div>
              <div className="col-md-6 relative">
                <label className="form-label">Maintenance:&nbsp;
                  <BootstrapTooltip 
                    title={maintenanceTitle}
                    placement="right-end"
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                    arrow
                  >
                    <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                  </BootstrapTooltip>
                </label>
                <span className="price">$</span>
                <input
                  type="number"
                  className="form-input"
                  onWheel={(e) => e.target.blur()}
                  value={numberRenderer(calculatedInputsData?.maintenance)}
                  onChange={(e) =>
                    onDataChange("calculated", "maintenance", e.target.value)
                  }
                />
              </div>
              <div className="col-md-6 relative">
                <label className="form-label">Taxes:&nbsp;
                  <BootstrapTooltip
                    title={taxesTitle}
                    placement="right-end"
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                    arrow
                  >
                    <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                  </BootstrapTooltip>
                </label>
                <span className="price">$</span>
                <input
                  type="number"
                  className="form-input"
                  onWheel={(e) => e.target.blur()}
                  value={numberRenderer(apiInputsData?.taxes)}
                  onChange={(e) => onDataChange("api", "taxes", e.target.value)}
                />
              </div>
              <div className="col-md-6 relative">
                <label className="form-label">Vacancy:&nbsp;
                  <BootstrapTooltip
                    title={vacancyTitle}
                    placement="right-end"
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                    arrow
                  >
                    <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                  </BootstrapTooltip>
                </label>
                <span className="price">$</span>
                <input
                  type="number"
                  className="form-input"
                  onWheel={(e) => e.target.blur()}
                  value={numberRenderer(calculatedInputsData?.vacancy)}
                  onChange={(e) =>
                    onDataChange("calculated", "vacancy", e.target.value)
                  }
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Mortgage Down Payment:&nbsp;
                  <BootstrapTooltip
                    title={mdPaymentTitle}
                    placement="right-end"
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                    arrow
                  >
                    <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                  </BootstrapTooltip>
                </label>
                <div className="relative">
                  <input
                    type="number"
                    className={"ps-2 form-input percent-input"}
                    onWheel={(e) => e.target.blur()}
                    value={numberRenderer(
                      calculatedInputsData?.downPaymentPercentage
                    )}
                    onChange={(e) => {
                      if (e.target.value.toString().length > 15) return;
                      setMortagePaymentPercent(
                        e.target.value.includes(".")
                          ? e.target.value.split(".").join("")
                          : e.target.value.toString()
                      );
                      onDataChange(
                        "calculated",
                        "downPaymentPercentage",
                        +e.target.value
                      );
                    }}
                  />
                  <span
                    className={`percent ${!mortagePaymentPercent && "d-none"}`}
                    style={{
                      left: "56px"
                    }}
                  >
                    %
                  </span>
                </div>
              </div>
              <div className="col-md-6 relative">
                <label className="form-label">Capital Expenditures:&nbsp;
                  <BootstrapTooltip
                    title={capExpTitle} 
                    placement="right-end"
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                    arrow
                  >
                    <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                  </BootstrapTooltip>
                </label>
                <span className="price">$</span>
                <input
                  type="number"
                  className="form-input"
                  onWheel={(e) => e.target.blur()}
                  value={numberRenderer(
                    calculatedInputsData?.capitalExpenditures
                  )}
                  onChange={(e) =>
                    onDataChange(
                      "calculated",
                      "capitalExpenditures",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Mortgage Interest Rate:&nbsp;
                  <BootstrapTooltip
                    title={mirTitle}
                    placement="right-end"
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                    arrow
                  >
                    <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                  </BootstrapTooltip>
                </label>
                <div className="relative">
                  <input
                    type="number"
                    className="ps-2 form-input"
                    onWheel={(e) => e.target.blur()}
                    value={numberRenderer(apiInputsData?.mortgageInterestRate)}
                    onChange={(e) => {
                      if (e.target.value.toString().length > 15) return;
                      setMortageInterest(
                        e.target.value.includes(".")
                          ? e.target.value.split(".").join("")
                          : e.target.value.toString()
                      );
                      onDataChange(
                        "api",
                        "mortgageInterestRate",
                        e.target.value
                      );
                    }}
                  />
                  <span
                    className={`percent ${!mortageInterest && "d-none"}`}
                    style={{
                      left: "56px"
                    }}
                  >
                    %
                  </span>
                </div>
              </div>
              <div className="col-md-6 relative">
                <label className="form-label">Property Management:&nbsp;
                  <BootstrapTooltip
                    title={propManagmentTitle}
                    placement="right-end"
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                    arrow
                  >
                    <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                  </BootstrapTooltip>
                </label>
                <span className="price">$</span>
                <input
                  type="number"
                  className="form-input"
                  onWheel={(e) => e.target.blur()}
                  value={numberRenderer(
                    calculatedInputsData?.propertyManagement
                  )}
                  onChange={(e) =>
                    onDataChange(
                      "calculated",
                      "propertyManagement",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="col-md-6 relative">
                <div className="d-flex align-items-center">
                  <label className="form-label">Mortgage Payment:&nbsp;
                    <BootstrapTooltip
                      title={mortagePaymentTitle}
                      placement="right-end"
                      enterTouchDelay={0}
                      leaveTouchDelay={10000}
                      arrow
                    >
                      <HelpOutlineOutlinedIcon data-html="true" fontSize='x-small' color='primary'/>
                    </BootstrapTooltip>
                  </label>
                  <p className="ms-2">
                    ${calculatedInputsData?.mortgagePayment}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
        <div className="text-center mt-5">
          <Button
            title="Re-run Analysis!"
            className="btn-analysis"
            onClick={onReRun}
            color="#279ac8"
          />
        </div>
      </div>
      <Footer />
      <div className="version-content" style={{ bottom: "-12px" }}>
        <h5>Beta release</h5>
      </div>
    </div>
  );
};

export default AnalysisDetail;
