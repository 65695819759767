const initialState = {
  userData: { username: "", token: "" },
  search: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return {
        ...state,
        userData: action.userData
      };
    }
    case "LOGOUT_USER": {
      return {
        ...state,
        userData: initialState.userData,
        search: ""
      };
    }
    case "SEARCH": {
      return {
        ...state,
        search: action.data,
        analysisOutput: action.analysisOutput
      };
    }
    default:
      return state;
  }
};
export default reducer;
