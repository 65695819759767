import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import Button from "../../../components/Button";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import { search } from "../../../store/action";
import { Outlet } from "react-router-dom";

import { styled } from "@mui/material/styles";
import AWS from "aws-sdk";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import "./style.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

const Analysis = () => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.gray
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.gray
    }
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { username, token } = useSelector((state) => {
    return state.userData;
  });
  const [address, setAddress] = useState("");
  const [loader, setLoader] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [reCaptchaValidated, setReCaptchaValidated] = useState(false);

  const handleAutoComplete = (query) => {
    setIsLoading(true);
    setAddress(query);
    const SEARCH_URI =
      "https://www.zillowstatic.com/autocomplete/v3/suggestions";

    axios
      .get(`${SEARCH_URI}?q=${query}+&clientId=homepage-render`)
      .then(({ data }) => {
        setOptions(data?.results);
        setIsLoading(false);
      });
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;
  // AWS.
  const client = new AWS.Service({
    endpoint:
      "https://w4k3ya81hi.execute-api.us-east-2.amazonaws.com/prod/property",
    credentials: new AWS.Credentials({
      accessKeyId: AWS.config.credentials.accessKeyId,
      secretAccessKey: AWS.config.credentials.secretAccessKey,
      sessionToken: AWS.config.credentials.sessionToken
    }),
    region: "us-east-2",
    httpOptions: "POST"
  });

  const handleSearch = () => {
    console.log({ address, username, client });
    if (!address?.length) return swal("Please enter address.");
    setLoader(true);
    client
      .makeRequest(
        "POST",
        { userId: username, address: address },
        (err, data) => console.log({ err, data })
      )
      .on("sign", (req) => console.log({ req }));
    axios
      .post(
        "https://w4k3ya81hi.execute-api.us-east-2.amazonaws.com/prod/property",
        {
          userId: username,
          address: address
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
            "X-Api-Key": "CHCZCNwnZ96mxnF4khlvH92c7Sdn3SobzgUuVGb1"
          }
        }
      )
      .then((response) => {
        if (response?.data?.Message === "Success") {
          setLoader(false);
          dispatch(
            search({
              data: address,
              analysisOutput: response.data?.AnalysisOutput
            })
          );
          //console.log("Navigating to /analysis/details");
          navigate("/details");
        } else {
          setLoader(false);
          swal(
            response?.data?.Message ||
              "Sorry! We were not able to process that property, please double check the input address, and use the following format: <Street>, <City>, <State> <Zipcode>"
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log({err});
        if (err.response?.data?.message) {
          swal(err.response.data?.message);
        } else if (err.code === "ERR_NETWORK") {
          swal(
            "Your auth token has expired, please refresh the page and try again."
          );
        } else {
          swal(
            "Sorry! We were not able to process that property, please double check the input address, and use the following format: <Street>, <City>, <State> <Zipcode>"
          );
        }
      });
  };

  // reCaptcha Verification
  const handleVerify = useCallback(async (token) => {
    try {
      const captchaValidation = await axios.post(
        "https://w4k3ya81hi.execute-api.us-east-2.amazonaws.com/prod/recaptcha",
        {
          secret: "6Lfm8A8nAAAAADscqVeaMgC9HE3iSwLhcqRN_bVX",
          response: token
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": "CHCZCNwnZ96mxnF4khlvH92c7Sdn3SobzgUuVGb1"
          }
        }
      );
      if (captchaValidation?.data?.success) {
        console.log("reCAPTCHA validated successfully");
        setReCaptchaValidated(true);
      } else {
        console.log("reCAPTCHA validation failed");
        setReCaptchaValidated(false);
      }
    } catch (verifyError) {
      setReCaptchaValidated(false);
    }
  }, []);

  const longText = (
    <div>
      <div>
        Enter the address of a US residential property that you would like to
        analyze. The tool will find as much data as it can about that property,
        and run the initial analysis for you. You can then refine the inputs and
        analyze all of its major cash flow and profitability metrics!
      </div>
      <div>
        <br></br>
      </div>
      <div>
        <b>Recommended format:</b>
      </div>
      <div>1. Street, City, State Zip</div>
      <div>
        2. If applicable, you can add an Apartment number to the
        &nbsp;&nbsp;&nbsp;&nbsp;street line, or as a second line with an
        additional &nbsp;&nbsp;&nbsp;&nbsp;comma.
      </div>
      <div>3. State should be a two letter abbreviation</div>
    </div>
  );

  return (
    <div className="min-h-100">
      <Header />
      <div className="container d-flex flex-column justify-content-center align-items-center vh-70">
        <h2 className="text-main">Rental Property Analysis Tool</h2>
        <p className="sub-text">
          Estimate rental property cash flow metrics in seconds!
        </p>
        <div className="search-wrapper">
          <div className="search-col">
            <div className="search-box">
              <AsyncTypeahead
                filterBy={filterBy}
                id="async-example"
                isLoading={isLoading}
                labelKey={(option) =>
                  `${option?.metaData?.streetNumber} ${
                    option?.metaData?.streetName
                  } ${option?.metaData?.unitNumber ? "#" : ""} ${
                    option?.metaData?.unitNumber || ""
                  }, ${option?.metaData?.city}, ${option?.metaData?.state} ${
                    option?.metaData?.zipCode
                  }`
                }
                minLength={2}
                delay={500}
                onSearch={handleAutoComplete}
                searchText="finding a property address..."
                options={options}
                placeholder="Enter an address"
                size="lg"
                onChange={(e) => {
                  const metaData = e[0]?.metaData;
                  const address = `${metaData?.streetNumber || ""} ${
                    metaData?.streetName || ""
                  } ${metaData?.unitNumber ? "#" : ""} ${
                    metaData?.unitNumber || ""
                  } ${
                    metaData?.streetNumber && metaData?.streetName ? "," : "" // conditional coma separator
                  } ${metaData?.city || ""} ${
                    metaData?.city ? "," : "" // conditional coma separator
                  } ${metaData?.state} ${metaData?.zipCode}`;
                  // metadata?.streetNumber +
                  // " " +
                  // metadata?.streetName +
                  // ", " +
                  // metadata?.city +
                  // ", " +
                  // metadata?.state +
                  // " " +
                  // metadata?.zipCode;
                  setAddress(address);
                }}
                onBlur={(event) => setAddress(event.target.value)}
                renderMenuItemChildren={(option, props, idx) => {
                  const { metaData } = option;
                  return (
                    <Highlighter search={props.text}>
                      {`${metaData?.streetNumber || ""} ${
                        metaData?.streetName || ""
                      } ${metaData?.unitNumber ? "#" : ""} ${
                        metaData?.unitNumber || ""
                      } ${
                        metaData?.streetNumber && metaData?.streetName
                          ? ","
                          : "" // conditional coma separator
                      } ${metaData?.city || ""} ${
                        metaData?.city ? "," : "" // conditional coma separator
                      } ${metaData?.state} ${metaData?.zipCode}`}
                    </Highlighter>
                  );
                }}
              />
              <i className="fas fa-search search-icon"></i>
            </div>
            <p className="example">
              <b>Ex:</b> 15 Prince Street, Middletown, NY 10940&nbsp;&nbsp;
              <BootstrapTooltip
                title={longText}
                placement="top"
                enterTouchDelay={0}
                leaveTouchDelay={15000}
                arrow
              >
                <HelpOutlineOutlinedIcon
                  fontSize="x-small"
                  data-html="true"
                  color="primary"
                />
              </BootstrapTooltip>
            </p>
          </div>
          <div className="btn-col">
            <Button
              disabled={loader}
              title={
                loader ? (
                  <>
                    <i className="spin fas fa-spinner"></i>
                    <span className="ms-2">Run Analysis!</span>
                  </>
                ) : (
                  "Run Analysis!"
                )
              }
              color="#2791c8"
              className="btn-analysis"
              onClick={handleSearch}
            />
          </div>
        </div>
        <div>
          <GoogleReCaptcha onVerify={handleVerify} />
        </div>
      </div>
      <Footer />
      <div className="version-content">
        <h5>Beta release</h5>
      </div>
      <Outlet />
    </div>
  );
};

export default Analysis;
