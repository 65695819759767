import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import store, { persistor } from "./store";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey="6Lfm8A8nAAAAAIJVRv9ykY_CNBJ4vxpbzYygPSk4"
        // language="[optional_language]"
        useRecaptchaNet={false}
        useEnterprise={true}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: "head", // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
        }}
      >
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </GoogleReCaptchaProvider>
    </Provider>
  </React.StrictMode>
);
